import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { useState } from "react"
import logo from "../images/logo.png"
import WhyRoutecapBannerMobile from "../images/WhyRoutecapBannerMobile.png"
import Amit from "../images/Amit.png"
import AmitMobile from "../images/AmitMobile.png"

import WhyUs_1 from "../images/WhyUs-1.png"
import WhyUs_2 from "../images/WhyUs-2.png"
import WhyUs_3 from "../images/WhyUs-3.png"

import Unique1 from "../images/Unique1.png"
import Unique2 from "../images/Unique2.png"
import Unique3 from "../images/Unique3.png"

// import icon7 from "../images/icon7.png"
// import icon7 from "../images/icon7.png"
// import icon7 from "../images/icon7.png"

const WhyRouteCap = () => {
  const [visible, setVisible] = useState(false)
  return (
    <div>
      {/* SECTION -1 */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>RouteCap</title>
      </Helmet>
      <div
        className="md:grid lg:grid-rows-6 md:grid-rows-8 lg:h-screen why-routecap-banner"
        id="home"
      >
        <div className="md:row-span-1">
          <header className="lg:px-16 px-6 bg-purple flex flex-wrap items-center lg:py-0 py-2 nav-background-whyUs ">
            <div className="flex-1 flex justify-between items-center">
              <a href="/">
                <img src={logo} className="logo mt-4" />
              </a>
            </div>

            <label
              class="pointer-cursor lg:hidden block"
              onClick={() => setVisible(!visible)}
            >
              <svg
                className="fill-current "
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <title>menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </label>
            <div
              className={
                visible
                  ? "visible-class hidden lg:flex lg:items-center lg:w-auto w-full"
                  : "hidden-class hidden lg:flex lg:items-center lg:w-auto w-full"
              }
              id="menu"
            >
              <nav>
                <ul class="lg:flex items-center justify-between text-base text-white pt-4 lg:pt-0">
                  <li>
                    <Link
                      to="/"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      <strong>Home</strong>
                    </Link>
                  </li>
                  <li>
                    <a
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                      href="#home"
                    >
                      <strong>Why RouteCap?</strong>
                    </a>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy/"
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      <strong>Privacy Policy</strong>
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/home#modules"
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Modules
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      class="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                      href="/home/#modules"
                    >
                      Modules
                    </a> */}
                  {/* </li> */}
                  {/* <li>
                    <Link
                      to="/home#features"
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Features
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                      href="/home/#features"
                    >
                      Features
                    </a> */}
                  {/* </li>
                  <li>
                    <Link
                      to="/home#industries"
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                    >
                      Industries
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400"
                      href="http://localhost:8000/home/#industries"
                    >
                      Industries
                    </a> */}
                  {/* </li>
                  <li>
                    <Link
                      to="/home#contact-us"
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                    >
                      Contact Us
                    </Link> */}
                  {/* <a
                      onClick={() => setVisible(!visible)}
                      className="lg:p-4 py-3 px-0 block border-b-2 border-transparent hover:border-indigo-400 lg:mb-0 mb-2"
                      href="/home#contact-us"
                    >
                      Contact Us
                    </a> */}
                  {/* </li> */}
                </ul>
              </nav>
            </div>
          </header>
        </div>

        <div
          id="home"
          className="md:grid xxl:row-span-5 lg:row-span-5 sm:grid sm:w-full "
        >
          <div className="lg:hidden md:w-full sm:w-full">
            <img src={WhyRoutecapBannerMobile} />
            <div className="lg:w-3/4 md:w-full sm:w-full lg:p-24 md:p-12 xs:p-8 mobile-banner-content">
              <h1 className="lg:w-3/4 md:w-full homeheading-whyRouteCap ">
                Why RouteCap ?
              </h1>
              <hr class="horizontal-line-whyRoutecap"></hr>
              <hr class="horizontal-line-whyRoutecap"></hr>
              <p className="xl:w-1/2 lg:w-1/2 md:w-3/4 xs:w-full lg:pl-3 homesubtitle-whyRouteCap ">
                The smarter you plan your route, the more economical your
                resource costs and the more time you save.
              </p>
              <div className="xl:mt-20">
                {/* <Link to="/home">
                  <button class="bg-transparent text-blue-700 border border-purple-500 demo-button ">
                    Book a demo
                  </button>
                </Link> */}
                <Link to="/#contact-us">
                  <button class="bg-transparent demo-button ">
                    Book a demo
                  </button>
                </Link>
              </div>
              <br></br>
            </div>
          </div>

          <div className="lg:w-3/4 md:w-full sm:w-full xxl:p-20 md:p-12 xs:p-8 banner-content">
            <h1 className="lg:w-3/4 md:w-full lg:pl-3 homeheading-whyRouteCap ">
              Why RouteCap ?
            </h1>
            <hr class="horizontal-line-whyRoutecap"></hr>
            <p className="xl:w-1/2 lg:w-1/2 md:w-3/4 xs:w-full lg:pl-3 homesubtitle ">
              The smarter you plan your route, the more economical your resource
              costs and the more time you save.
            </p>
            <div className="xl:mt-20">
              <Link to="/#contact-us">
                <button class="bg-transparent text-blue-700 border border-purple-500 demo-button ">
                  Book a demo
                </button>
              </Link>

              {/* <a href="http://localhost:8000/home/#industries">
                <button class="bg-transparent  demo-button ">
                  Book a demo
                </button>
              </a> */}
            </div>
            <br></br>
          </div>
        </div>
      </div>

      {/* Section - 2 */}

      <div className="lg:flex xs:grid">
        <div className="xxl:p-20 lg:w-1/2 sm:w-full md:p-12 xs:p-8">
          <h1 className="w-full heading pt-5 pb-5 lg:text-left xs:text-center">
            Motivation Behind
          </h1>
          <hr class="horizontal-line-whyRoutecap"></hr>
          <p className="w-full body-text subtitle py-8 ">
            Routecap is developed under the mentorship of Dr. Amit Garg. He
            holds a Guinness World Record in mathematics and presented India in
            global championships. He worked in USA for a decade doing complex
            optimization projects leading to millions of dollar savings for
            market leaders like Amazon, Penske and Genesys. It is developed by
            an experienced team of Scientists from top tier institutes who have
            successfully delivered 30+ Operations Research consulting projects
            for 20+ Global clients with 8 clients having turnover more than 1
            Billion USD. This product would optimize the costs and operationally
            benefit any logistics or 3PL provider who would need to optimize
            their resources without affecting any operational constraints.
          </p>

          <div className="w-full xxl:w-3/4 flex rounded-r-lg ormae-link-box ">
            <div className="leftRect"></div>
            <p className="body-text subtitle p-4 mb-0">
              To learn more about us and the services offered, please visit
              <a
                href="https://www.ormae.com/"
                className="link-text"
                target="_blank"
              >
                <strong>www.ormae.com</strong>
              </a>
            </p>
          </div>
        </div>

        <div className="lg:w-1/2 xs:w-full">
          <img src={Amit} className="motivation-background" />
        </div>

        <div className="lg:hidden xs:w-full">
          <img src={AmitMobile} className="mb-0" />
        </div>
      </div>

      {/* Section -  3 */}

      <div id="why-us " class="xxl:p-20 md:p-12 xs:p-6 p-12 featureLeftBack">
        <h1 className="heading pt-5 pb-5 lg:text-left xs:text-center">
          Why Us ?
        </h1>
        <hr class="horizontal-line-whyRoutecap"></hr>

        <div className="flex flex-wrap sm:pt-20 xs:pt-6 md:pb-20 ">
          <div className="xxl:w-full sm:w-full why-us-div">
            <div className="div-center">
              {/* <div class="icon-div">
                <img src={icon7} alt="Logo" className="img" />
              </div> */}
              <div className="rightRect rounded-r-lg p-6 ">
                <img src={WhyUs_1} alt="Logo" className="img" />
                <p className=" title card-title ">20+ Global Clients</p>
                <p className="body-text subtitle">
                  WHY US? ORMAE has served clients across geography mainly USA,
                  UAE, Germany, India, Japan and provided custom built complex
                  optimization problems in Supply Chain & Logistics.
                </p>
              </div>
            </div>

            <div className="div-center">
              {/* <div class="icon-div"> */}
              {/* <img src={icon7} alt="Logo" className="img" />/ */}
              {/* </div> */}
              <div className="rightRect rounded-r-lg p-6 ">
                <img src={WhyUs_2} alt="Logo" className="img" />
                <p className=" title card-title ">8+ Clients Over 1B USD</p>
                <p className="body-text subtitle">
                  Out of the client base , more than 8 clients have a complex
                  operation with revenue over 1B USD. Customized solutions
                  expertise and know how have been put into this product
                </p>
              </div>
            </div>

            <div className="div-center">
              {/* <div class="icon-div"> */}
              {/* <img src={icon7} alt="Logo" className="img" />/ */}
              {/* </div> */}
              <div className="rightRect rounded-r-lg p-6 ">
                <img src={WhyUs_3} alt="Logo" className="img" />
                <p className=" title card-title ">Considerable Savings</p>
                <p className="body-text subtitle">
                  ORMAE has built ininnovative optimization and data science
                  algorithms to bring inconsiderable cost savings and efficiency
                  inplanning. These algorithms along with best in class User
                  Interface makes us distinct
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="why-us " class="xxl:p-20 md:p-12 xs:p-6 p-12 xl:h-screen">
        <h1 className="heading pt-5 pb-5 lg:text-left xs:text-center">
          What Makes It Unique
        </h1>
        <hr class="horizontal-line-whyRoutecap"></hr>
        <section class="carousel" aria-label="Gallery">
          <ol class="carousel__viewport">
            <li id="carousel__slide1" tabindex="0" class="carousel__slide">
              <div class="carousel__snapper lg:flex xs:grid">
                <a href="#carousel__slide3" class="carousel__prev">
                  Go to last slide
                </a>
                <a href="#carousel__slide2" class="carousel__next">
                  Go to next slide
                </a>
                <div className="lg:w-1/2 xs:w-full">
                  <img src={Unique1} alt="Logo" className="img" />
                </div>
                <div className="xxl:w-1/2 lg:w-1/2 xs:w-full xxl:mt-40 xl:mt-24 lg:mt-16 carousel-text-center">
                  <p className="title card-title lg:w-3/4 xs:w-full">
                    Multi Depot Time Windows Capacited Vehicle Routing
                  </p>
                  <p className="body-text subtitle lg:w-3/4 xs:w-full pt-8">
                    Innovative vehicle route planning with a well calibrated
                    time distance matrix using custom built optimization and
                    data science models. Model caters to real time as well as
                    short term planning
                  </p>
                </div>
              </div>
            </li>
            <li id="carousel__slide2" tabindex="0" class="carousel__slide">
              <div class="carousel__snapper lg:flex xs:grid">
                <a href="#carousel__slide1" class="carousel__prev">
                  Go to previous slide
                </a>
                <a href="#carousel__slide3" class="carousel__next">
                  Go to next slide
                </a>
                <div className="lg:w-1/2 xs:w-full">
                  <img src={Unique2} alt="Logo" className="img" />
                </div>
                <div className="xxl:w-1/2 lg:w-1/2  xs:w-full xxl:mt-40 xl:mt-24 lg:mt-16 carousel-text-center">
                  <p className="title card-title lg:w-3/4 xs:w-full">
                    Parametric Simulations for Optimizer Scenario Planning
                  </p>
                  <p className="body-text subtitle lg:w-3/4 xs:w-full pt-8">
                    Out of the client base , more than 8 clients have a complex
                    operation with revenue over 1B USD. Customized solutions
                    expertise and know how have been put into this product
                  </p>
                </div>
              </div>
            </li>
            <li id="carousel__slide3" tabindex="0" class="carousel__slide">
              <div class="carousel__snapper lg:flex xs:grid">
                <a href="#carousel__slide2" class="carousel__prev">
                  Go to previous slide
                </a>
                <a href="#carousel__slide1" class="carousel__next">
                  Go to next slide
                </a>
                <div className="lg:w-1/2 xs:w-full">
                  <img src={Unique3} alt="Logo" className="img" />
                </div>
                <div className="xxl:w-1/2 lg:w-1/2 xs:w-full xxl:mt-40 xl:mt-24 lg:mt-16 carousel-text-center">
                  <p className="title card-title lg:w-3/4 xs:w-full">
                    Dynamic Gantt View for Manual Allocations and Driver Mobile
                    App
                  </p>
                  <p className="body-text subtitle lg:w-3/4 xs:w-full pt-8">
                    Dynamic Gantt provides ease of allocating unassigned
                    invoices to routes while looking into all violating
                    constraints on the fly. Driver mobile app for order and map
                    details
                  </p>
                </div>
              </div>
            </li>
          </ol>
          <aside class="carousel__navigation">
            <ol class="carousel__navigation-list">
              <li class="carousel__navigation-item">
                <a href="#carousel__slide1" class="carousel__navigation-button">
                  Go to slide 1
                </a>
              </li>
              <li class="carousel__navigation-item">
                <a href="#carousel__slide2" class="carousel__navigation-button">
                  Go to slide 2
                </a>
              </li>
              <li class="carousel__navigation-item">
                <a href="#carousel__slide3" class="carousel__navigation-button">
                  Go to slide 3
                </a>
              </li>
            </ol>
          </aside>
        </section>
      </div>

      <footer className="xl:pt-0 xs:pt-10">
        <div>
          <div className="copyright">
            <p> Copyright © 2020 Ormae. All rights reserved</p>
          </div>
          <div className="social-icons"></div>
        </div>
      </footer>
    </div>
  )
}

export default WhyRouteCap
